import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, createVNode as _createVNode } from "vue"
import _imports_0 from '@/assets/logo.png'


const _hoisted_1 = { class: "tooltip" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("nav", {
    class: _normalizeClass(["sidebar", { mobile: _ctx.isMobile, tablet: _ctx.isTablet }])
  }, [
    _createElementVNode("div", {
      class: "logo-container",
      onMouseover: _cache[0] || (_cache[0] = ($event: any) => (!_ctx.isMobile && (_ctx.showTooltip = false))),
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleTooltip && _ctx.toggleTooltip(...args)))
    }, [
      _cache[2] || (_cache[2] = _createElementVNode("img", {
        src: _imports_0,
        alt: "Logo",
        class: "logo"
      }, null, -1)),
      _createElementVNode("h1", {
        class: _normalizeClass(["title", { hidden: _ctx.isTablet || _ctx.isMobile }])
      }, "Boundary", 2),
      _createVNode(_Transition, { name: "slide-fade" }, {
        default: _withCtx(() => [
          _withDirectives(_createElementVNode("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.routes, (route) => {
              return (_openBlock(), _createBlock(_component_router_link, {
                key: route.path,
                to: route.path,
                class: "tooltip-link"
              }, {
                default: _withCtx(() => [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(route.icon), { class: "icon" })),
                  _createElementVNode("span", null, _toDisplayString(route.name), 1)
                ]),
                _: 2
              }, 1032, ["to"]))
            }), 128))
          ], 512), [
            [_vShow, _ctx.showTooltip && _ctx.isMobile]
          ])
        ]),
        _: 1
      })
    ], 32),
    _createElementVNode("div", {
      class: _normalizeClass(["nav-links", { hidden: _ctx.isMobile }])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.routes, (route) => {
        return (_openBlock(), _createBlock(_component_router_link, {
          key: route.path,
          to: route.path,
          class: "nav-link"
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(route.icon), { class: "icon" })),
            _createElementVNode("span", {
              class: _normalizeClass(["link-text", { hidden: _ctx.isTablet }])
            }, _toDisplayString(route.name), 3)
          ]),
          _: 2
        }, 1032, ["to"]))
      }), 128))
    ], 2)
  ], 2))
}