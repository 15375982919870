import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withModifiers as _withModifiers, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isVisible)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "modal-overlay",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
      }, [
        _createElementVNode("div", {
          class: "modal-content",
          onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modalItems, (item, index) => {
            return (_openBlock(), _createElementBlock("button", {
              class: _normalizeClass(["modal-btn", {
          'first-btn': index === 0,
          'last-btn': index === _ctx.modalItems.length - 1,
        }]),
              key: index,
              onClick: ($event: any) => (_ctx.handleItemClick(item))
            }, _toDisplayString(item), 11, _hoisted_1))
          }), 128))
        ])
      ]))
    : _createCommentVNode("", true)
}