import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "post-container" }
const _hoisted_2 = { class: "photo-header" }
const _hoisted_3 = { class: "user-info" }
const _hoisted_4 = { class: "user-name" }
const _hoisted_5 = { class: "photo-actions" }
const _hoisted_6 = {
  key: 0,
  class: "navigation"
}
const _hoisted_7 = { class: "text-section" }
const _hoisted_8 = { class: "action-buttons" }
const _hoisted_9 = { class: "post-caption" }
const _hoisted_10 = { class: "comments-summary" }
const _hoisted_11 = { class: "comments-list" }
const _hoisted_12 = { class: "comment-user" }
const _hoisted_13 = { class: "comment-text" }
const _hoisted_14 = { class: "post-date-and-comment" }
const _hoisted_15 = { class: "post-date" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProfileView = _resolveComponent("ProfileView")!
  const _component_EllipsisHorizontalIcon = _resolveComponent("EllipsisHorizontalIcon")!
  const _component_ActionModal = _resolveComponent("ActionModal")!
  const _component_HeartIcon = _resolveComponent("HeartIcon")!
  const _component_ChatBubbleBottomCenterTextIcon = _resolveComponent("ChatBubbleBottomCenterTextIcon")!
  const _component_PaperAirplaneIcon = _resolveComponent("PaperAirplaneIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "photo-section",
      style: _normalizeStyle({ backgroundImage: `url(${_ctx.post.imageUrls[_ctx.currentPhotoIndex]})` }),
      onMouseover: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showNavigation = true)),
      onMouseleave: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showNavigation = false)),
      onTouchstart: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showNavigation = true))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_ProfileView, {
            username: _ctx.post.author.username,
            size: 80,
            class: "user-icon"
          }, null, 8, ["username"]),
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.post.author.username), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_EllipsisHorizontalIcon, {
            class: "action-btn info",
            onClick: _ctx.openModal
          }, null, 8, ["onClick"])
        ]),
        _createVNode(_component_ActionModal, {
          isVisible: _ctx.isModalVisible,
          onClose: _ctx.closeModal
        }, null, 8, ["isVisible", "onClose"])
      ]),
      (_ctx.showNavigation)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("button", {
              class: "nav-btn left",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.previousPhoto && _ctx.previousPhoto(...args)))
            }, "◀"),
            _createElementVNode("button", {
              class: "nav-btn right",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.nextPhoto && _ctx.nextPhoto(...args)))
            }, "▶")
          ]))
        : _createCommentVNode("", true)
    ], 36),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_HeartIcon, {
          class: _normalizeClass(["like-icon", { active: _ctx.isLiked }]),
          onClick: _ctx.toggleLike
        }, null, 8, ["class", "onClick"]),
        _createTextVNode(" " + _toDisplayString(_ctx.post.likes.length) + " ", 1),
        _createVNode(_component_ChatBubbleBottomCenterTextIcon, {
          class: "chat-icon",
          onClick: _ctx.toggleChat
        }, null, 8, ["onClick"]),
        _createTextVNode(" " + _toDisplayString(_ctx.post.comments.length) + " ", 1),
        _createVNode(_component_PaperAirplaneIcon, {
          class: "share-icon",
          onClick: _ctx.toggleShare
        }, null, 8, ["onClick"])
      ]),
      _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.post.caption), 1),
      _createElementVNode("div", _hoisted_10, [
        _createTextVNode(" 댓글 " + _toDisplayString(_ctx.post.comments.length) + "개 ", 1),
        _createElementVNode("span", {
          class: "more-comments",
          onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.loadMoreComments && _ctx.loadMoreComments(...args)))
        }, "더보기")
      ]),
      _createElementVNode("div", _hoisted_11, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.post.comments, (comment) => {
          return (_openBlock(), _createElementBlock("div", {
            key: comment.id,
            class: "comment-item"
          }, [
            _createElementVNode("span", _hoisted_12, _toDisplayString(comment.author.username) + ":", 1),
            _createElementVNode("span", _hoisted_13, _toDisplayString(comment.content), 1)
          ]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_14, [
        _withDirectives(_createElementVNode("input", {
          type: "text",
          class: "comment-input",
          placeholder: "댓글을 입력하세요",
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.commentInput) = $event)),
          onKeypress: _cache[7] || (_cache[7] = _withKeys(
//@ts-ignore
(...args) => (_ctx.submitComment && _ctx.submitComment(...args)), ["enter"]))
        }, null, 544), [
          [_vModelText, _ctx.commentInput]
        ]),
        (_ctx.isCommentNotEmpty)
          ? (_openBlock(), _createBlock(_component_ChatBubbleBottomCenterTextIcon, {
              key: 0,
              class: "submit-icon",
              onClick: _ctx.submitComment
            }, null, 8, ["onClick"]))
          : _createCommentVNode("", true),
        _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.formatDate(_ctx.post.createdAt)), 1)
      ])
    ])
  ]))
}