import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "app",
  class: "app-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavigationSide = _resolveComponent("NavigationSide")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.isLoginPage)
      ? (_openBlock(), _createBlock(_component_NavigationSide, {
          key: 0,
          class: "nav-sidebar"
        }))
      : _createCommentVNode("", true),
    _createElementVNode("main", {
      class: _normalizeClass(["main-content", { 'no-sidebar': _ctx.isLoginPage }])
    }, [
      _createVNode(_component_router_view)
    ], 2)
  ]))
}