import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = {
  key: 0,
  class: "username"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "profile-container",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goToProfile && _ctx.goToProfile(...args))),
    onMouseover: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.showUsernameOnHover && _ctx.showUsernameOnHover(...args))),
    onMouseleave: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.hideUsernameOnLeave && _ctx.hideUsernameOnLeave(...args)))
  }, [
    _createElementVNode("img", {
      src: _ctx.profilePictureUrl,
      alt: `${_ctx.username}'s profile picture`,
      style: _normalizeStyle({ width: `${_ctx.size}px`, height: `${_ctx.size}px` }),
      class: "profile-picture"
    }, null, 12, _hoisted_1),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.showUsername)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.username), 1))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 32))
}