import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "home" }
const _hoisted_2 = { class: "centered-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PostItem = _resolveComponent("PostItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dummyPosts, (post) => {
        return (_openBlock(), _createBlock(_component_PostItem, {
          key: post.id,
          post: post
        }, null, 8, ["post"]))
      }), 128))
    ])
  ]))
}